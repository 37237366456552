
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [

        {
            path: '/',
            name: 'home', 
            component: () => import('../views/storeView.vue'),  // 直接将 storeView 作为主页组件
        },

        {
            path: '/store', 
            name: 'store',
            component: () => import('../views/storeView.vue'),
        },        
        {
            path: '/customKit',
            name: 'customKit',
            component: () => import('../views/CustomKit.vue'),
        },
    ]
}
)



router.beforeEach((to, from, next) => {
    document.title = "3c3u商店"
    next()
  })

 
export default router
