<template>
  <router-view>

  </router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
   
  }
}
</script>

<style>

#app {
  /* font-family:mc; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
</style>
