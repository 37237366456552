import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueGtag from 'vue-gtag'
import VueMatomo from 'vue-matomo'


createApp(App).mount('#app')


const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

  
app.use(VueMatomo, {
  host: 'https://3c3ustore.matomo.cloud/',
  siteId: 1
});
app.use(ElementPlus)
app.config.globalProperties.$axios = axios
app.use(router)
app.use(VueGtag, {
  config: { id: 'G-L8XHYVWK99' }
})

app.mount('#app')

window._paq.push(['trackPageView']); 

axios.defaults.baseURL = "https://3c3u.store/"
